import React from 'react';
import '../assets/sass/grayscale.scss';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import jean from '../assets/images/Jean-Marie.png';
import scott from '../assets/images/Scott.png';
import vichit from '../assets/images/Vichit.png';
import frank from '../assets/images/logo.png';
import maxx from '../assets/images/Maxx.png';
import nicole from '../assets/images/Nic.png';
import tucker from '../assets/images/Tucker.png';
import tanner from '../assets/images/Tanner.png';
import hannah from '../assets/images/Hannah.png';
import mikayla from '../assets/images/Mikayla.png';
import grace from '../assets/images/Grace.png';
import grandma from '../assets/images/grandma.jpg';
import demoImage from '../assets/images/demo-image-02.jpg';
import girls from '../assets/images/girls.jpg';


/**
 * About Page is one of the main page, it displays all the information about the people in twobeta.
 */

const AboutPage = () => (
<Layout>
    <Header />
    <div>
            <section id="about" className=" bg-light pb-3" style = {{paddingTop: 140}}>
                <div className="container">
                    <div className="row align-items-center no-gutters mb-4 ">
                        <h2> OUR STORY</h2>
                    </div>
                    <div className="row align-items-center no-gutters">
                        <p>I spent the Summer of 2019 working for a large finance company in New York City. </p>
                        <p>Buzzing with life, the city’s fabled and endless energy, fuelled by intense competition and stress, led to the neither surprising nor uncommon sight of my peers spending thousands of dollars on a single weekend night, numbing themselves against the pressures of corporate life.</p>
                        <p>In spite of all of our global financial know-how, it was easier to stay blissfully ignorant in our first-world bubbles. Coming from a family of Latin American descent, however, it was impossible for me to ignore my personal awareness of the cost of a single, extravagant night out in New York City, and its monetary value equivalent in the third-world --  a home for a struggling family.</p>
                        <p>The turning point came when, late one evening in a subway station, I saw a homeless man crash face-first into cement, before being immediately rushed to the hospital. The visceral image of his dire circumstance against my disconnected privilege shocked me into action and moved me to look beyond myself.</p>
                        <p>After a night of researching the conditions of various global economies in the hopes of directing my desires to serve those in need, I decided to book a flight to Guatemala. </p>
                        <p>In Guatemala, I was impacted by the beauty of the country and the warmth of its people. However, I also saw firsthand the pervasive impact that poverty has had on rural Central American economies and their infrastructures.</p>
                        <p>It was through conversations with the people that I realized: they were not without desire to improve their lives; rather, it was a fundamental lack of access to opportunity and education that stifled, and continues to hinder the growth and sustainment of local economies in developing countries.</p>
                        <p>Two Beta’s mission, therefore, is centered around inspiring resilient, homegrown economic growth in rural communities through the provision of necessary resources and mutual partnership.</p>
                    </div>
                    <div className=" row no-gutters mt-2">
                        <div className= "col-md-4 pr-1">
                        <img src={grandma} class="w-100" alt="..." />
                        </div>
                        <div className= "col-md-4 pr-1">
                        <img src={demoImage} class="w-100" alt="..." />
                        </div>
                        <div className= "col-md-4 pr-1">
                        <img src={girls} class="w-100" alt="..." />
                        </div>
                    </div>
                </div>
            </section>
        </div>
 
    <section id="ourteam" className=" bg-light pt-4">
      <div class="container bg-light">
        <h2> OUR TEAM </h2>
        <div className="row no-gutters">
          <Vignette source={maxx} title={"Maxx"} text={"Maxx Johnson is the founder and CEO of Two Beta. Maxx and his partner Scott Jordan decided for every home they purchase through Arrived Investment Co, they would build a home in developing communities and scholarship the children each home supports. Maxx's prior experience includes internships at global and domestic Venture Capital funds. He spent two summers trading FX options at Deutsche Bank."} />
          <Vignette source={nicole} title={"Nic"} text={"Nicole Lai, originally from Singapore, ran a major media campaigns in Junior College leading to a half million dollar increase in governmental funding for 5 years for local home-ventilation systems. She is currently working as a financial analyst for Silicon Slopes fastest growing start-up, Route."} />
          <Vignette source={tucker} title={"Tucker"} text={"Tucker is a freelance commercial and event cinematographer based in Salt Lake City. After working as a tech supervisor for the International Sundance Film Festival and being involved with multiple Films, a TLC reality show, and commercial productions, Tucker got his start in journalism, directing and shooting his first international documentary in 2019, following Venezuelan refugees on their journey through the mountains of Columbia. Since then he has found a passion, using his abilities to spread awareness of people of the 3rd world & their stories."} />
          <Vignette source={grace} title={"Grace"} text={"Grace has been involved with the Stella Oaks Foundation for the last several years. Grace has had experience in fundraising, marketing and project coordination. Grace studied journalism at Utah Valley University and heads the marketing and community relations efforts of Two Beta."} />
          <Vignette source={hannah} title={"Hannah"} text={"Hannah spent a year and a half living in Guatemala as a religious missionary for the Church of Jesus Christ of Latter-Day Saints. There she learned the Spanish language as well as learning to love the people and their culture. Currently, Hannah is a junior at Brigham Young University working on her BA degree in Art Education with a minor in Dual Language Immersion. Hannah finds great joy in traveling the world, meeting new people and studying art, and feels that art is one of the greatest representations of any people."} />
          {/* <Vignette source={mikayla} title={"Mikayla"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed id semper risus in hendrerit gravida rutrum quisque."} /> */}
        </div>
      </div>
    </section>

    <section id="advisors" className=" bg-light">
      <div class="container">
        <h2>ADVISORS</h2>
        <div className="row no-gutters">
          <Vignette source={jean} title={"Jean-Marie Menga"} text={"Jean-Marie grew up in a rural village in Cameron, Africa. Stemming from his childhood, and extending through his career managing industrial M&A deals, Jean-Marie has comprehensive insight into the kinds of projects that succeed in developing countries, and how to manage relationships in small communities within."} />
          <Vignette source={vichit} title={"Vichit Ith"} text={"After graduating with a Masters Degree in macroeconomic policies and capital markets at Harvard University, Vichit has been a direct advisor to foreign Presidents and Prime Ministers across Africa and Southeast Asia on rapid inclusive economic development and institutional restructuring over the past 40 years."} text2 = { " With profound knowledge on trends and issues faced by developing countries across the world, Vichit is uniquely positioned to advise Two Beta's operational structures and strategies in the third world."} />
          <Vignette source={frank} title={"Frank Johnson"} text={"Having spent significant time in South America and an entire career in healthcare consulting and healthcare technology, Frank has a deep knowledge of both domestic and foreign healthcare industry structures and healthcare needs. Frank also is fluent in Spanish and helps manage Two Beta's latin relationships."}/>
          <Vignette source={scott} title={"Scott Jordan"} text={"Scott, founder of Arrived, has been an active investor in real estate since 2016. With experience performing underwriting and due diligence on $500M+ acquisitions, Scott has managed opening, setup and operations of 40+ real estate developments."} text2 = { "Having completed his education at the Marriott School of Business with a BS in Strategic Management, Scott's keen eye for discerning and shaping real estate-centric ecosystems of business development. "} />
        </div>
      </div>
    </section>

{/* 
    <section id="ourteam" className="projects-section bg-light">
      <div class="container">
        <h2>OUR PARTNERS</h2>
      </div>
    </section> */}
    <SocialLinks />
    <Footer />
  
  </Layout>
);

const Vignette = ({ source, title, text, text2 }) => (

  <div class="container m-5 col-sm">
  <div class="row">
    <div class="span4">
      <img class="float-right m-2 shadow-lg rounded-full" alt={title} src= {source} style={{ width: 150, height: 150, borderRadius: '50%' }}/>
      <div class="content-heading"><h3>{title} </h3></div>
      <p className = "vignette"  >{text}</p>
      <p className = "vignette"  >{text2} </p>
    </div>
  </div>
</div>


  // <div class="clearfix p-20" style={{ width: 250 }}>
  //     <h3 class="mt-8">{title}</h3>
  //     <p class="mt-8" style={{ width: 210 }}>{text}</p>
  //     <img alt={title} class="float-right ml-2 shadow-lg rounded-full" src={source}style={{ width: 200, height: 200, borderRadius: '50%' } } />
  // </div>
)

export default AboutPage;
