module.exports = {
  siteTitle: 'familiesforhomes', // <title>
  manifestName: 'familiesforhomes',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/familiesforhomes.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'familiesforhomes',
  subHeading: 'Inspiring resilient, homegrown economic growth in rural communities through education and partnership.',

  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/theanubhav/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
  ],
  
  email: 'info@twobeta.com',
  phone: '8015608069',
};

