import React from 'react';
import ArrivedFooter from '../assets/images/arrived-footer.svg';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50 pt-5">
      <div className="container" >
        <img src={ArrivedFooter} />
      </div>
      <div className="container pt-3">
        Copyright &copy; Two Beta 2020
      </div>
    </footer>
  );
}
